import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FaChevronDown } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../Context/AuthContext";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import Select from 'react-select';

const cities = {
    "Riyadh": 'الرياض',
    "AlGhat": 'الغاط',
    "ArRass": 'الرس',
    "AlAsyah": 'الاسياح',
    "AynIbnFuhayd": 'عين-ابن-فهيد',
    "AlBadayea": 'البدائع',
    "AlBukayriyah": 'البكيرية',
    "Buraydah": 'بريدة',
    "AdDilam": 'الدلم',
    "Dirab": 'ديراب',
    "Diriyah": 'الدرعية',
    "Dhurma": 'ضرما',
    "AlHariq": 'الحريق',
    "HowtatBaniTamim": 'حوطة-بني-تميم',
    "Huraymila": 'حريملاء',
    "HautatSudair": 'حوطة-سدير',
    'Al-Kharj': 'الخرج',
    "AlMajma'ah": 'المجمعة',
    'Al-Muzahmiya': 'محافظة-المزاحمية',
    "Unayzah": 'عنيزة',
    "AlUyaynah": 'العيينة',
    "AlQasab": 'القصب',
    "RiyadhAlKhabra": 'رياض-الخبراء',
    "Shaqra": 'شقراء',
    'AzZulfi': 'الزلفي',
    "AlMithnab": 'المذنب',
    "Jeddah": 'جدة',
    "Ushaiqer": 'أشيقر',
    "Tharmda": 'ثرمداء',
    "AlQarinah": 'القرينية',
    "AlJubaylah": 'الجبيلة',
    "AlMoqbelPalaces": 'قصور-أل-مقبل',
    "AdDubaiyah": 'الضبيعة',
    "AlAmajiyah": 'العماجية',
    "Naam": 'نعام',
    "AlHulwah": 'الحلوة',
    "Banban": 'بنبان',
    "Malham": 'ملهم',
    "Hasi": 'الحسي',
    "Sudair": 'سدير',
    "Tumair": 'تمير',
    "Tamriyah": 'تمرية',
    "Jalajil": 'جلاجل',
    "Harmah": 'حرمة',
    "Mulayh": 'مليح',
    "RaudatSudair": 'روضة-سدير',
    "UshairatSudair": 'عشيرة-سدير',
    "AudatSudair": 'عودة-سدير',
    "AlMu'ashibah": 'المعشبة',
    "Salbukh": 'صلبوخ',
    "Thadiq": 'ثادق',
    "UyunAlJawa": 'عيون-الجواء',
    "AlQuwarah": 'القوارة',
    "AshShimasiyah": 'الشماسية',
    "Qusaiba": 'قصيباء',
    "AlButayn": 'البطين',
    "AbaAlworood": 'ابا-الورود',
    "AlKhabra": 'الخبراء',
    "AnNabhaniyah": 'النبهانية',
    'Al-Fuwayliq': 'الفويلق',
    "Qasribn'Aqil": 'قصر-بن-عقيل',
    "AlDulaymiyah": 'الدليمية',
    "AshShihyah": 'الشيحية',
    "AlKhobar": 'الخبر',
    "Dammam": 'الدمام',
    "Anak": 'عنك',
    "AlAwamiyah": 'العوامية',
    "Dhahran": 'الظهران',
    "AlQatif": 'القطيف',
    "RasTanura": 'رأس-تنورة',
    "Safwa": 'صفوى',
    "AlJubail": 'الجبيل',
    "Makkah": 'مكة-المكرمة',
    "AlAwjam": 'الاوجام',
    "KhamisMushait": 'خميس-مشيط',
    "Abha": 'أبها',
    "AhadRafidah": 'أحد-رفيدة',
    "RijalAlmaa": 'رجال-ألمع',
    "Billasmar": 'بللسمر',
    "SaratAbidah": 'سراة-عبيدة',
    "Tendaha": 'تندحة',
    "Tanomah": 'تنومة',
    "Muhayil": 'محايل-عسير',
    "Saihat": 'سيهات',
    "Taif": 'الطائف',
    "Khulais": 'خليص',
    "Thuwal": 'ثول',
    "Asfan": 'عسفان',
    "KingAbdullahEconomicCity": 'مدينة-الملك-عبد-الله-الاقتصادية',
    "Dahaban": 'ذهبان',
    "AsSailAlKabeer": 'السيل-الكبير',
    "AsSaylasSaghir": 'السيل-الصغير',
    "AlQuwaiiyah": 'القويعية',
    "Jilah": 'الجله',
    "Alhada": 'الهدا',
    "AshShafa": 'الشفا',
    "Buqayq": 'بقيق',
    "NewAinDar": 'عين-دار-الجديدة',
    "OldAinDar": 'عين-دار-القديمة',
    "Fouda": 'فودة',
    "AlDughaimiyah": 'الدغيمية',
    "AlOyun": 'العيون',
    "AlHofuf": 'الهفوف',
    "AlWozeyh": 'الوزية',
    "AlMubarraz": 'المبرز',
    "Salasil": 'صلاصل',
    "MadinatalUmran": 'العمران',
    "AlAhsa": 'الاحساء',
    "Hyt": 'هيت',
    "AlHazim": 'الحزم',
    "AdDalfa'ah": 'الضلفعة',
    "AlKhasiybah": 'الخصيبه',
    "Dadh": 'ضيده',
    "Tarifqassim": 'طريف-القصيم',
    "AtTarafiyah": 'الطرفية',
    "UglatAsugour": 'عقلة-الصقور',
    "AlAmar": 'العمار',
    "AlPetra": 'البتراء',
    "Thadij": 'ثادج',
    "AlKhushaybi": 'الخشيبي',
    "AlFawwarah": 'الفوارة',
    "WadiIbnHashbal": 'وادي-ابن-هشبل',
    "AlWadeen": 'الواديين',
    "MilitaryCityHousing": 'سكن-المدينة-العسكرية',
    "AlReesh": 'الريش',
    "AlHenakiyah": 'الحناكية',
    "AlRathaya": 'الرذايا',
    "AsSuwadirah": 'الصويدرة',
    "AlShegrah": 'الشقرة',
    "AlMindassah": 'المندسة',
    "AlMulaylih": 'المليليح',
    "AlSelselah": 'الصلصلة',
    "AlThamad": 'الثمد',
    "Khaybar": 'خيبر',
    "Ushayrah": 'العشيرة',
    "WadiReem": 'وادي-ريم',
    "Alyutamah": 'اليتمة',
    "WadiAlFora'a": 'وادي-الفرع',
    "AlAkhal": 'الاكحل',
    "AlFuraysh": 'الفريش',
    "AlMusayjid": 'المسيجيد',
    "Badr": 'بدر',
    "Yanbu": 'ينبع',
    "AdDumayriyah": 'الضميرية',
    "AlHarara": 'الهرارة',
    "MahdadhDhahab": 'مهد-الذهب',
    "Madinah": 'المدينة-المنورة',
    "Jazan": 'جازان',
    "AbuArish": 'أبو-عريش',
    "Sabya": 'صبيا',
    "Damad": 'ضمد',
    "AhadAlMasarihah": 'أحد-المسارحة',
    "Samtah": 'صامطة',
    "Baish": 'بيش',
    "Duba": 'ضبا',
    "Tabuk": 'تبوك',
    "Shigry": 'شقري',
    "AlMuwaileh": 'المويلح',
    "Sharma": 'شرما',
    "Gayal": 'قيال',
    "Hail": 'حائل',
    "Baqaa": 'بقعاء',
    "AlGhazalah": 'الغزالة',
    "AlSulaimi": 'السليمي',
    "Mawqaq": 'موقق',
    "AsSilayyil": 'السليل',
    "Fayd": 'فيد',
    "AshShinan": 'الشنان',
    "Jubbah": 'جبة',
    "Bahrah": 'بحره',
    "Tarout": 'تاروت',
    "Sumaira'a": 'سميراء',
    "AlJithamiyah": 'الجثامية',
    "AlQaid": 'القاعد',
    "Ithmur": 'عثمر',
    "AlJumum": 'الجموم',
    "AlHawiyah": 'الحوية',
    "ZawralHarith": 'زور-الحارث',
    "Zalom": 'زلوم',
    "Yalamlam": 'يلملم',
    "Yadamah": 'يدمة',
    "Yabreen": 'يبرين',
    "Yaara": 'يعرى',
    "Wushai": 'وشي',
    "Waset": 'واسط',
    "WadiShora": 'وادي-شرى',
    "WadiBishah": 'وادي-بيشة',
    "WadiBadhan": 'وادي-ببيضان',
    "Wadi'Amoud": 'وادي-عمود',
    "WadiAlHayat": 'وادي-الحياة',
    'Wadiad-Dawasir': 'وادي-الدواسر',
    "Wabrah": 'وبرة',
    "Waaer": 'واعر',
    "WaadAlShamal": 'وعد-الشمال',
    "Uthmaniyah": 'العثمانية',
    "Uthal": 'أوثال',
    "Utayiq": 'عتيق',
    "Urayarah": 'عريعرة',
    "UmmRujoum": 'أم-رجوم',
    "UmmHazim": 'ام-حزم',
    "UmmAldoom": 'أم-الدوم',
    "UmmAlJamajm": 'أم-الجماجم',
    "Umluj": 'املج',
    "UmAshShifallah": 'ام-الشفلح',
    "UmAljerem": 'ام-الجرم',
    "UmAlerrad": 'ام-العراد',
    "Udhailiyah": 'العضيلية',
    "Ubayrah": 'أبيرة',
    "Turbah": 'تربة',
    "Turaif": 'طريف',
    "Tuhayyi": 'طحي',
    "Tubarjal": 'طبرجل',
    "Trubah": 'تربه-حائل',
    "Tara'ah": 'ترعة',
    "ThlothAlMandhar": 'ثلوث-المنظر',
    "Thar": 'ثار',
    "Thaj": 'ثاج',
    "Tibrak": 'تبراك',
    "AtTuwalah": 'الطوالة',
    "Tathleeth": 'تثليث',
    "Tereeb": 'طريب',
    "Tirah": 'ترعة',
    "Tanumah": 'التنومه',
    "Tanajib": 'تناجيب',
    "Tamniah": 'تمنية',
    "Tallan": 'طلان',
    "Tala'atNaza": 'تلعة-نزا',
    "Tabashah": 'تباشعة',
    "Tabalah": 'تبالة',
    "Tabab": 'طبب',
    "Suwayr": 'صوير',
    "SulailahJuhainah": 'سليلة-جهينة',
    "Suhailah": 'سحيلة',
    "Sude'a": 'صديع',
    "Starah": 'ستارة',
    "Shuwaq": 'شواق',
    "Shumran": 'شمران',
    "Shoqsan": 'شقصان',
    "Shoaiba": 'الشعيبة',
    "Shifiyah": 'شفية',
    "Shedgum": 'شدقم',
    "Shaybah": 'الشيبة',
    "Shawyah": 'شوية',
    "Sharorah": 'شرورة',
    "Shari": 'شري',
    "Shaghab": 'شغب',
    "AlSayyabah": 'الصيابة',
    "Sayadah": 'صيادة',
    "Sayaa": 'سعيا',
    "Saq": 'ساق',
    "SamratAlJed": 'سمرة-الجد',
    "Sammed": 'الصمد',
    "Samhah": 'سمحة',
    "Samakh": 'صمخ',
    "Salwa": 'سلوى',
    "SalmAlzwaher": 'سلم-الزواهر',
    "Sakaka": 'سكاكا',
    "Sajir": 'ساجر',
    "Safinah": 'صفينة',
    "SabtAljarah": 'سبت-الجارة',
    "SabtShamran": 'سبت-شمران',
    "Saabar": 'صعبر',
    "Sa'ad": 'سعّد',
    "Ruwayghib": 'رويغب',
    "Alraqita": 'الرقيطاء',
    "Rijal": 'رجال-ألمع',
    "Reghawah": 'رغوة',
    "RawdatHabbas": 'روضة-هباس',
    "Ranyah": 'رنية',
    "Rahat": 'رهاط',
    "Rafha": 'رفحاء',
    "RafayaAlJemsh": 'رفائع-الجمش',
    "Radwan": 'رضوان',
    'Al-Radefah': 'الرديفة',
    "Qudaid": 'قديد',
    "QlayybKhedr": 'قليّب-خضر',
    "Qina": 'قناء',
    "Qilwah": 'قلوة',
    "Qia": 'قيا',
    "Qbah": 'قبه',
    "QaryatAlUlya": 'قرية-العليا',
    "Qara": 'قارا',
    "Qana": 'قنا',
    "Osran": 'العسران',
    "Nubayha": 'نبيها',
    "Nkheelan": 'نخيلان',
    "Nita": 'نطاع',
    "Nimran": 'نمران',
    "Nifi": 'نفي',
    "NewMuwayh": 'المويه-الجديد',
    "Nebak": 'نباك',
    "Namerah": 'نمرة',
    "Najran": 'نجران',
    "Nairyah": 'النعيرية',
    "Nabat": 'نبط',
    "Naajan": 'نعجان',
    "Musaddah": 'مصدة',
    "Munifah": 'منيفة',
    "Mulayjah": 'مليجة',
    "Muhayriqah": 'محيرقة',
    "Mudaysis": 'مديسيس',
    "Mudarraj": 'المدرج',
    "Mubhel": 'مبهل',
    "Mubayid": 'مبايض',
    "Mu'fija": 'معفيجة',
    "Morighan": 'مريغان',
    "Mughayra": 'مغيراء',
    "MishashJurud": 'مشاش-جرود',
    "Mishalah": 'مشلَة',
    "Minwa": 'منوى',
    "Mighati": 'مغطي',
    "AlGafrat": 'الغفرات',
    "Meegowa": 'ميقوع',
    "Maysaan": 'ميسان',
    "Mastorah": 'مستورة',
    "Masader": 'مصادر',
    "MarjAllsoban": 'معرج-السوبان',
    "Marabah": 'مربة',
    "Maqra": 'مقرة',
    "Manshabah": 'منشبة',
    "Malakan": 'ملكان',
    "Maqshush": 'مخشوش',
    "Madrakah": 'مدركة',
    "Linah": 'لينه',
    "Layla": 'ليلى',
    "Lahumah": 'لاهومة',
    "Kumdah": 'كمدة',
    "KingKhalidMilitaryCity": 'مدينة-الملك-خالد-العسكرية',
    "Khurais": 'خريص',
    "Khuff": 'الخف',
    "KhefHussain": 'خيف-حسين',
    "Khbash": 'خباش',
    "Khathaam": 'خثعم',
    "KhamisMutair": 'خميس-مطير',
    "KhamisHarb": 'خميس-حرب',
    "Khamaseen": 'الخماسين',
    "Khairan": 'خيران',
    'KhaiberAl-Janoub': 'خيبر-الجنوب',
    "Khafji": 'الخفجي',
    "Keyad": 'كياد',
    "Kahla": 'كحلا',
    "Judah": 'جودة',
    "AthThybiyah": 'الذيبية',
    "Jefin": 'جفن',
    "Jash": 'جاش',
    "Jarab": 'جرب',
    "Jamajm": 'جماجم',
    "Ithrah": 'إثرة',
    "Isharah": 'عشرة',
    "Hubuna": 'حبونا',
    "Fadhilah": 'فضيلة',
    "HijratAlHarah": 'هجرة-الحارة',
    "Hibran": 'حبران',
    "Hefar": 'حفار',
    "Hedeb": 'هديب',
    "Hezwa": 'حزوى',
    "HazemAljalamid": 'حزم-الجلاميد',
    "Harub": 'هروب',
    "Haradh": 'حرض',
    "Hanidh": 'حنيذ',
    "Hanak": 'حنك',
    "Halban": 'حلبان',
    "Halaba": 'حلباء',
    "Hajrah": 'الحجرة',
    "Hajar": 'حجر',
    "HafrAlAtk": 'حفر-العتك',
    "HafiratNisah": 'نساح',
    "HafirKishb": 'حفر-كشب‬',
    "HafarAlBatin": 'حفر-الباطن',
    "Wuthaylan": 'وثيلان',
    "HaddatashSham": 'هدى-الشام',
    "Haddadah": 'هدادة',
    "AlHuwayd": 'الحويض',
    "Hedban": 'هدبان',
    "HadaadBaniMalek": 'حداد-بني-مالك',
    "Habil": 'الحبيل-القوز',
    "Habeel": 'الحبيل',
    "Habbes": 'حبس',
    "Ghtai": 'غطي',
    "Ghran": 'غران',
    "Ghomygah": 'غميقة',
    "Ghizlan": 'غزلان',
    "Ghazaial": 'غزايل',
    "Ghanwa": 'غنوى',
    "Ghamrah": 'غمرة',
    "Gaabah": 'جعبة',
    "FeyadhTabrjal": 'فياض-طبرجل',
    "Fayfa": 'فيفاء',
    "FarasanIsland": 'جزيرة-فرسان',
    "Farasan": 'فرسان',
    "Fadhla": 'فضلا',
    "Enaker": 'عنيكر',
    "Either": 'عثر',
    "EinAsSuwaine": 'عين-الصوينع',
    'Dumatal-Jandal': 'دومة-الجندل',
    "Dulayhan": 'دليهان',
    "DulayRasheed": 'ضليع-رشيد',
    "Duhknah": 'دخنة',
    "Dubay'ah": 'ضبيعة',
    "Dremeha": 'دريميحة',
    "Doga": 'دوقه',
    "Doos": 'قبائل-دوس',
    'Dhubaya-Jumah': 'الظبية-والجمعة',
    "Dhalm": 'ظلم',
    "DhahranAlJanub": 'ظهران-الجنوب',
    "BadrAlJanoub": 'بدر-الجنوب',
    "Dariyah": 'ضرية',
    "Bisha": 'بيشة',
    "Dabir": 'دبير',
    "Da'a": 'ضاعا',
    "Burudan": 'برودان',
    "Bnyyazed": 'بني-يزيد',
    "HalatAmmar": 'حالة-عمار',
    "BirAskar": 'بئر-عسكر',
    "Baljurashi": 'بلجرشي',
    "BiladBinySuhaim": 'بلاد-بني-سهيم',
    "BidahValley": 'وادي-بيده',
    "Beda'aBenKhalaf": 'بدع-بن-خلف',
    "AlBaheem": 'البهيم',
    "Bashout": 'باشوت',
    "Bariq": 'بارق',
    "BaniSaad": 'بني-سعد',
    "BaniMouhamad": 'قرية-بني-محمد',
    "BaniMazin": 'بني-مازن',
    "BaniKabir": 'بني-كبير',
    "BaniHasan": 'بني-حسن',
    "BaniHamim": 'بني-هميم',
    "BaniAmr": 'بني-عمرو',
    "Balqarn": 'بلقرن',
    "AlBida": 'البدع',
    "AzZughayn": 'الزغين',
    "AzZemah": 'الزيمة',
    "AZzalal": 'الزَّلال',
    "AzZafir": 'الزفير',
    "Awirah": 'عويرة',
    "Thebea": 'الذيبية',
    "Ateef": 'عطيف',
    "AlAtawilah": 'الاطاولة',
    "AtTuwaim": 'التويم',
    "AtTawdihiyah": 'التوضيحية',
    "Asuwayq": 'السٌويق',
    "Ashayrah": 'عشيرة',
    "AlShuqaiq": 'الشقيق',
    "AshShubaykiyah": 'الشبيكية',
    "AshShu'bah": 'الشعبة',
    "AshShu'ara": 'الشعراء',
    "AshShamlul": 'الشملول',
    "AshShamli": 'الشملي',
    "AshShahmah": 'الشحمة',
    "Asbtar": 'أسبطر',
    "AsSulaymaniyah": 'السليمانية',
    "AsSuh": 'الصوح',
    "AsSufayri": 'الصفيري',
    "AsSudayrah": 'السديرة',
    "AsSuairah": 'السعيرة',
    "AsSihaf": 'الصحاف',
    "AsSarrar": 'الصرّار',
    "AsSam'uriyah": 'الصمعورية',
    "AsSalmanyah": 'السلمانية',
    "AsSaffaniyah": 'السفانية',
    "AsSaddainBalharith": 'السدين-بالحارث',
    "AsSaddain": 'السدين',
    "AsSadawi": 'الصداوي',
    "Baqran": 'بقران',
    "AlArtawiyah": 'الأرطاوية',
    "Arman": 'عرمان',
    "arja": 'عرجاء',
    "Arar": 'عرعر',
    "ArRuwaidhah": 'الرويضة',
    "ArRuqi": 'الرقعي',
    "ArRayn": 'الرين',
    "ArRayis": 'الرايس',
    "ArRawdah": 'الروضة',
    "ArRafiah": 'الرفيعة',
    "ArRafi'ah": 'الرفيعة',
    "AnNughayq": 'النغيق',
    "AnNazim": 'مركز-النظيم',
    "AnNazayim": 'النظايم',
    "AnNabkAbuQasr": 'النبك-أبو-قصر',
    "Amaq": 'عمق',
    "AmaaerBenSana'a": 'عمائر-بن-صنعاء',
    "Alwasqah": 'الوسقة',
    "Alshwaaq": 'الشواق',
    "Alsalhiya": 'الصالحية',
    "Alsadyah": 'السعدية',
    "Alquaba": 'القعبة',
    "Alqarei": 'القريع',
    "Alqalh": 'الغالة',
    "Almuzayri": 'المزيرع',
    "Almuzaylif": 'المظيلف',
    "AlMu'tadil": 'المعتدل',
    "Almorshediah": 'المرشدية',
    "Almnadh": 'المناضح',
    "AlMihdar": 'المحدار',
    "AlMandaq": 'المندق',
    "AlMajardah": 'المجاردة',
    "Allaghamees": 'اللغاميس',
    "Alkwifriah": 'الكويفرية',
    "Alkhuraybah": 'الخريبة',
    "AlKahafah": 'الكهفة',
    "Aljfarah": 'الجفارة',
    "Alhumaimah": 'الحميمة',
    "Alhumaid": 'قرية-الحميد',
    "Alhrateem": 'الحراتيم',
    "Alfera": 'الفرع',
    "AlFareeq": 'مخطط-الفريق-والسلامة',
    "Alduwaid": 'الدويد',
    "Albarek": 'البارك',
    "Nasfa": 'الناصفة',
    'Al-Nasfah': 'الناصفة',
    'Al-BadieAl-Shamali': 'البديع-الشمالي',
    "AlAhmar": 'الأحمر',
    "AlWase'ah": 'الوسيعة',
    "AlWuhait": 'الوهيط',
    "Alwastah": 'الواسطة',
    "AlWannan": 'الونان',
    "AlWadiah": 'الوديعة',
    "AlUwayqilah": 'Al-Uwayqilah',
    "AlUqlah": 'العقلة',
    "AlUla": 'العلا',
    "AlUferiah": 'العفيرية',
    "AlTorkiyah": 'الطرقية',
    "AtTiraq": 'الطراق',
    "AlTheniah": 'الثنية',
    "AtTurfiyahAshSharqiyah": 'الطرفيه-الشرقيه',
    "AlSufah": 'الصفاح',
    "AlSubaykhah": 'الصبيخة',
    "AlSir": 'السر',
    "AlShlayil": 'الشلايل',
    "AlShfeah": 'الشفية',
    "AlShatt": 'الشط',
    "AlSharjah": 'الشرجة',
    "AlShadakh": 'الشدخ',
    "AlShabitah": 'الشابطة',
    "AlShabhah": 'الشبحة',
    "AlShaaf": 'الشعف',
    "AlSedr": 'السدر',
    "AlSalhaniyah": 'الصلحانية',
    "AlReeth": 'الريث',
    "AlRamdhah": 'الرمضة',
    "AlRafaie": 'الرفائع',
    "AlRadha": 'الردحة',
    "AlQurayyat": 'القريات',
    "AlQurayn": 'القرين',
    "AlQunfudhah": 'القنفذة',
    "AlQulayyib": 'القليب',
    "AlQuamah": 'القوامة',
    "AlQrash": 'القراصة',
    "AlQouz": 'القوز',
    "AlQirah": 'القيرة',
    "AlQaraa": 'القرعاء',
    "AlQanboor": 'القنبور',
    "AlQaiyah": 'القاعية',
    "AlQaisumah": 'القيصومة',
    "AlQahma": 'القحمة',
    "AlQadeimah": 'القضيمة',
    "AlQaa": 'القاع',
    "AlQa'": 'القاع',
    "AlOus'": 'العوص',
    "AlOmaq": 'العمق',
    "AlNuaibea'a": 'النويبع',
    "AlNamas": 'النماص',
    "AlNagaf": 'النجف',
    "AlNabah": 'النباه',
    "AlMuraba'a": 'المربع',
    "AlMukaily": 'المكيلي',
    "AlMufrihat": 'المفرحات',
    "AlMudayyih": 'المضيح',
    "AlMothalath": 'المثلث',
    "AlMishaliah": 'المشعلية',
    'Al-Mayyah': 'المياه',
    "Almwared": 'المواريد',
    "AlMassamah": 'المسماة',
    "AlMarameh": 'المرامية',
    "AlMaqrah": 'المقراه',
    "Magna": 'مقنا',
    "AlMakhwah": 'المخواة',
    "AlMahdam": 'المدام',
    "AlMahani": 'المحاني',
    "AlMadha": 'المضة',
    "AlMadeed": 'المديد',
    "AlLsawiyah": 'العيساوية',
    "AlLith": 'الليث',
    "AlLaqayit": 'اللقائط',
    "AlLahabah": 'اللهابة',
    "AlKhuznah": 'الخزنة',
    "AlKhurma": 'الخرمة',
    "AlKhuffiyah": 'الخفية',
    "AlKhitah": 'الخطة',
    "AlKhasrah": 'الخاصرة',
    "AlKhashah": 'الخشعة',
    "AlKharma": 'الخرماء',
    "AlKharkhir": 'الخرخير',
    "AlKhaniq": 'الخانق',
    "AlKhalas": 'الخلاص',
    "AlKamil": 'الكامل',
    "AlKadis": 'الكديس',
    "AlKadarah": 'الكدرة',
    "AlJurayfah": 'الجريفه',
    "AlJudaida": 'الجديدة',
    "AlJehfa": 'الجحفة',
    "AlJifah": 'الجفة',
    "AlJialah": 'الجعلة',
    "AlJernafh": 'الجرنافة',
    "AlJawshan": 'الجوشن',
    "AlJawah": 'الجوة',
    "AlJaizah": 'الجائزة',
    "Aljadid": 'الجديد',
    "AlIjliyah": 'العجلية',
    "AlHusayy": 'الحسي',
    "AlHusayniyah": 'الحصينية',
    "AlHunayy": 'الحني',
    "AlHumiyat": 'الحوميات',
    "AlHulayfahAsSufla": 'الحليفة-السفلى',
    "AlHufayr": 'الحفير',
    "AlHinnah": 'الحناة',
    "AlHijf": 'الحجف',
    "AlHifah": 'الحيفة',
    "AlHendeyah": 'الهندية',
    "AlHenayah": 'الحناية',
    "AlHefnah": 'الحفنة',
    "AlHati": 'الحطي',
    "AlHathera": 'الحثيرة',
    "AlHasuniyyah": 'الحيسونية',
    "AlHaridhah": 'الحريضة',
    "AlHarajah": 'الحرجة',
    "AlHaqu": 'الحقو',
    "AlHamad": 'الحماد',
    "AlHait": 'الحائط',
    "AlHafayer": 'الحفاير',
    "AlHadithah": 'الحديثة',
    "Kabd": 'كبد',
    "AlHaddar": 'الهدار',
    "AlHabala": 'الحبلة',
    "AlGhurabah": 'الغرابه',
    "AlGhayl": 'الغيل',
    "AlGawamshah": 'القوامشة',
    "AlFuruthi": 'الفروثي',
    "AlFuqayyir": 'الفقير',
    "AlFigrah": 'الفقرة',
    "AlFatiha": 'الفطيحة',
    "AlFarshah": 'الفرشة',
    "AlFarridah": 'الفريدة',
    "AlFaraah": 'الفارعه',
    "AlFaqa'ali": 'الفقعلي',
    "AlEshash": 'العشاش',
    "AlEdabi": 'العيدابي',
    "AlDuwadimi": 'الدوادمي',
    'Al-Disah': 'الديسة',
    "AlDhahreyah": 'الظاهرية',
    "AlBraber": 'البرابر',
    "AlBirk": 'البرك',
    "AlBir": 'البير',
    "AlBijadyah": 'البجادية',
    "AlBatilah": 'البتيلة',
    "AlBatha": 'البطحاء',
    "AlBashayer": 'البشائر',
    "AlBashair": 'البشائر',
    "AlBarzah": 'البرزة',
    "AlBahah": 'الباحة',
    'Al-BadieAl-Janobi': 'البديع-الجنوبي',
    "AlBad'": 'البدع',
    "AlAzzah": 'ال-عزة',
    "AlAzaherah": 'قرية-الأزاهرة',
    "AlAwamer": 'العوامر',
    "AlAtheeb": 'العذيب',
    "AlArtawi": 'الأرطاوي',
    "AlAroos": 'العروس',
    "Itwad": 'عتود',
    "AlAqiq": 'العقيق',
    "AlAmoah": 'الأمواه',
    "AlAmlah": 'الأملح',
    "AlAjfar": 'الأجفر',
    "AlAithmah": 'العيثمة',
    "AlAis": 'العيص',
    "AlAflaj": 'الافلاج',
    "AlAdari": 'الأضارع',
    "AlAbyar": 'الأبيار',
    "Al-Abwa'": 'الأبواء',
    "AlAbar": 'العبار',
    "Al'Uwainid": 'العويند',
    "Al'Adhiriyah": 'العاذرية',
    "Airj": 'عرج',
    "AhadBaniZayd": 'أحد-بني-زيد',
    "Afraa": 'عفراء',
    "Afqara": 'أفقراء',
    "Afifh": 'Afifh',
    "Afif": 'عفيف',
    "Adham": 'أضم',
    "Addayer": 'الدائر',
    "AdDarb": 'الدرب',
    "AbuSidayrah": 'أبو-سديرة',
    "AbuShakeyr": 'أبو-شكير',
    "AbuSalama": 'أبو-سلامة',
    "AbuRakh": 'أبو-راكة',
    "AbuMuloh": 'أبو-ملوح',
    "AbuHaramel": 'أبو-حرامل',
    "AbuHadriya": 'أبو-حدرية',
    "AbuDhiba": 'ابوضباع',
    "AbuRadif": 'أبو-الرديف',
    "AbuAjram": 'أبو-عجرم',
    "AboHalaefa": 'أبوحليفاء-ملح',
    "Abayt": 'ابيط',
    "AlKhashabiyah": 'الخشابية',
    "Rabigh": 'رابغ',
    "Tayma": 'تيماء',
    "AlAridhah": 'العارضة',
    "AlBirkah": 'البركة',
    "AsSufun": 'السفن',
    "AtTurbiyah": 'التربية',
    "AlQalibah": 'القليبة',
    "BirIbnHirmas": 'بئر-ابن-هرماس',
    "Haql": 'حقل',
    "AlHijaziyah": 'الحجازية',
    "AlSeeh": 'السيح',
    "Quraytah": 'قريطة',
    "Subaih": 'صبيح',
    "RawdatGradan": 'روضة-قردان',
    "Maraghan": 'مرغان',
    "RawdAlJawa": 'روض-الجواء',
    "gafAlJawa": 'غاف-الجواء',
    "ArRubayiyah": 'الربيعية',
    "AlNabqiyyah": 'النبقية',
    "Bandariyah": 'البندرية',
    "TarifAlasyah": 'طريف-الاسياح',
    "AlBrood": 'البرود',
    "Hunaydhl": 'حنيظل',
    'Al-Siq': 'السيق',
    "Marat": 'مرات',
    "AlShabain": 'الشعبين',
    "AlJarf": 'الجرف',
    "BahrAbuSukaynah": 'بحر-أبو-سكينة',
    "AlSarhan": 'آل-سرحان',
    "Alfaraaien": 'الفرعين',
    "Algayed": 'أبو-القعائد',
    "Alsilaa": 'أبو-السلع',
    "AalOtayf": 'ال-عطيف',
    "Ababh": 'عببه',
    "AhlSufran": 'أهل-السفران',
    "Alqararah": 'القرارة',
    "Ajama": 'الجماء',
    "AlatTafilah": 'ال-الطفيلة',
    "AbuMa'raj": 'قريه-أبو-العرج-بعبس',
    "AbuAddarbh": 'أبو-الضربة',
    "'Abualtuyur": 'ابو-الطيور',
    "AbuHajar": 'أبو-حجر',
    "AbuMadh": 'أبو-مضه',
    "AdDahinah": 'الداهنة',
    "AdDahis": 'آل-الداحس',
    "AglatIbenJabreen": 'العقلة',
    "AlMa'abooj": 'المعبوج',
    "AlEinAlHarrah": 'العين-الحارة',
    "Alaliya": 'العالية',
    "AlArjain": 'العرجين',
    "Alaroug": 'العروج',
    "Alasamlah": 'الأساملة',
    "AlAseelh": 'العسيلة',
    "Alashah": 'قرية-العشة',
    "Albadawi": 'البدوي',
    "Albahir": 'الباحر',
    'AL-BATNA': 'البطنة',
    "AlbakhtahSouth": 'البختة-الجنوبية',
    "Albouq": 'البوق',
    "AlDagharir": 'الدغارير',
    "Aldhahrah": 'الظهرة',
    "Alduraeah": 'الدريعية',
    "Alfaijah": 'الفائجة',
    "AlFara": 'الفرعاء',
    "Alfaraah": 'الفرعة',
    "AlFarhaniyah": 'الفرحانية',
    "Alfawaleqah": 'الفوالقة',
    "AlFoqaha": 'الفقهاء',
    "AlGamri": 'القمري',
    "RawdatAlgaraa": 'روضة-سمحان',
    "AlGharib": 'الغريب',
    "Algofol": 'القفل',
    "Alhthan": 'الحضن',
    "AlHadror": 'الحضرور',
    "AlHasah": 'الأحساء',
    "AlHaeer": 'الحائر',
    "AlHasher": 'الحشر',
    "AlHawt": 'الحوط',
    "Alhijfar": 'الحجفار',
    "Alhumayra": 'الحميراء',
    "AlHusayni": 'الحسيني',
    "AlHuwaidy": 'الهويدي',
    "AlJahow": 'الجهو',
    "AlJarad": 'الجرد',
    "Aljashiah": 'الجيشية',
    "AlJoroof": 'الجروف',
    "Alkanabish": 'الكنابيش',
    "AlKasib": 'الكاسب',
    "AlKhabbah": 'الخبة',
    "AlKhadra'": 'الخضراء',
    "AlKhelfh": 'الخلفه',
    "AlKhoms": 'الخمس',
    "AlKhutamah": 'الخطامة',
    "AlLaqiya": 'اللقية',
    'Al-Moaqqas': 'المعقص',
    "AlMabda'": 'المبادع',
    "AlMadaya": 'المضايا',
    "AlMahalani": 'المحلاني',
    "AlMahash": 'المهاش',
    "Almansoria": 'المنصورية',
    "Almarkh": 'المرخ',
    "AlMarkuz": 'المركوز',
    "AlMarrekheiah": 'المريخية',
    'Al-Matan': 'المطعن',
    "AlMawshitNorth": 'الموشت-الشمالية',
    "AlMawshitSouth": 'الموشت-الجنوبية',
    "AlMehalhala": 'المحلحلة',
    "AlMehtash": 'المهتش',
    "AlMejammah": 'المجامة',
    "Mijannah": 'المجنة',
    "AlMekhashaleia": 'المخشلية',
    "AlMeqarqam": 'المقرقم',
    "AlMesaidah": 'المصايدة',
    "AlMinjarh": 'المنجارة',
    "AlMalida": 'المليداء',
    "Almogali": 'المقالي',
    "Almokambal": 'المكمبل',
    "AlMishash": 'المشاش',
    "AlMu'ayqilat": 'المعيقلات',
    "AlMuthab": 'قرية-مثاب',
    "AlMatrafiyah": 'المطرفية',
    "Muwassam": 'الموسم',
    "AlnafeiNorth": 'النافعي-الشمالية',
    "AlNafeiSouth": 'النافعي-الجنوبية',
    "Alnajameiah": 'النجامية',
    "AnNisiyah": 'النيصية',
    "Alnoaimat": 'النعيمات',
    "AlQadab": 'القضب',
    "AlBadi'": 'البديع-والقرفي',
    "Alrawnah": 'الرونه',
    "AlRayyan": 'الريان',
    "AlreemRacetrack": 'حلبة-الريم-الدولية',
    "Alrweas": 'الرويس',
    "AlSadiliyah": 'السادلية',
    "AlSawadi": 'السودي',
    "AlSefah": 'الصفة',
    "AlSehi": 'السهي',
    "AlSha'afoleiah": 'الشعفولية',
    "AshShamiyah": 'الشامية',
    "Alsharaf": 'الشرف',
    "AlSharia": 'الشريعة',
    "Alshebaikah": 'الشبيكه',
    "AsSubayhyah": 'الصبيحية',
    "Alsoudah": 'السودة',
    "Alsourah": 'الصورة',
    "AlTuwal": 'الطوال',
    "AlUlya": 'العليا',
    "AlWahabah": 'الوهابة',
    "Alrahmah": 'قرية-آل-رحمة',
    "AlWasah": 'الوسعة',
    "Wasit": 'قرية-الواسط',
    "Alwasly": 'الواصلي',
    "AlWeted": 'الوتد',
    "AlWuday": 'الودي',
    "AlZabedah": 'الزبيدة',
    "AlAmaaria": 'العمارية',
    "AnNibaj": 'النباج',
    "AlMubarakah": 'المباركة',
    "UmmalTorab": 'أم-التراب',
    "Alarish": 'العريش',
    "Ardah": 'عردة',
    "Arrzha": 'الرزح',
    "AlSeddarah": 'السدارة',
    "AsSahwah": 'الصهوة',
    "AsSufurrat": 'الصفرات',
    "AsSunayna": 'الصنيناء',
    "AshShaalaniyah": 'الشعلانية',
    "Tahriyah": 'الطاهرية',
    "BadaiIdyan": 'بدائع-العضيان',
    "Bani'Isá": 'بني-عيسى',
    "BiladMashwal": 'ال-مشول',
    "Bi'radDibba": 'بير-الدبا',
    "Bi'ralMashi": 'أبيار-الماشي',
    "Bi'rLij'ah": 'لجعة',
    "Buwatah": 'بواتة',
    "Dubaport": 'ميناء-ضبا',
    "Duqm": 'الدقم',
    "esialh": 'عسيلة',
    "Faya": 'الفية',
    "FaydatIbnSuwaylim": 'فيضة-إبن-سويلم',
    "BaishDam": 'سد-وادي-بيش',
    "Ghilanah": 'غيلانة',
    "Guntan": 'قنطان',
    "Hadabah": 'هدبة',
    "Hajanbah": 'الهجنبة',
    "Hajrufah": 'حجرفة',
    "HakimatabiArish": 'حاكمة-ابي-عريش',
    "Harshaf": 'الحرشف',
    "HayjahMuraqqah": 'هيجة-مرقة',
    "AbuArishindustrialzone": 'المنطقة-الصناعية-بأبو-عريش',
    "Jaww": 'جو',
    "JazanAlolya": 'جازان-العليا',
    "Jeham": 'جهام',
    "Jidanah": 'قذانة',
    "Joribah": 'جريبة-الشقيري',
    "Juwai": 'جوي',
    "kabshan": 'كبشان',
    "Kaalol": 'كعلول',
    "KhabtSaeed": 'خبت-سعيد',
    "KersAyash": 'كرس-عياش',
    "LakeofJazanDam": 'واحة-سد-وادي-جازان',
    "Khathirah": 'خضيرة',
    "KhatwatAlAin": 'خطوة-العين',
    "KhaevAlhzame": 'خيف-الحزامي',
    "Khojarah": 'الخوجرة',
    "Khudair": 'خضير',
    "KhudayratAyash": 'خضيرة-عياش',
    "Ijzala": 'اجزالا',
    "Khumayah": 'خميعة',
    "Mansih": 'منسيه',
    "Malgocta": 'ملقوطة',
    "Masfag": 'المصفق',
    "Masliyah": 'مسلية',
    "MishashasSuhul": 'مشاش-السهول',
    "Mizail": 'مزعل',
    "Mizhirah": 'مزهرة',
    "Musabis": 'المصبح',
    "MunifatalQa'id": 'منيفة-القاعد',
    "Musaykah": 'مشخصه',
    "UqlatIbnDani": 'عقلة-إبن-داني',
    "AlNkheel": 'النخيل',
    "RadisAlasfal": 'رديس-الأسفل',
    "RadisAlaly": 'رديس-الأعلى',
    "Ramlan": 'رملان',
    "Rumah": 'رماح',
    "SalamahalDarag": 'سلامة-الدرج',
    "Sadus": 'سدوس',
    "Saddeqah": 'صديقة',
    "Samoda": 'سامودة',
    "ShaabAltheeb": 'شعب-الذيب',
    "Shatifiyah": 'الشطيفية',
    "Shuaiha": 'شويهة',
    "Shuqayri": 'الشقيري',
    "Siabh": 'صيابة',
    "SuqalSabt": 'سوق-السبت',
    "Suqallayl": 'سوق-الليل',
    "Alsirdah": 'السرداح',
    "Swanah": 'صوانة',
    "AtTurfiyahalGharbiyah": 'الطرفية-الغربية',
    "UmKedad": 'أم-كداد',
    "Umm'Ishar": 'أم-عشر',
    "AlHabib": 'آل-حبيب',
    "Uthaithiah": 'أثيثية',
    "AlUqayr": 'العقير',
    "YanbuAlNakhal": 'ينبع-النخل',
    "AlWajh": 'الوجه',
    "WadiMarabah": 'وادي-مربة',
    "AlZahbain": 'الزهبين',
    "Zibala": 'زبالا',
    "Dharghat": 'ضرغط',
    "Zamzam": 'زمزم',
    "RawdatAlHisu": 'روضة-الحسو',
    "Thumair": 'ثمير',
    "HullatalAhwass": 'حلة-الأحوس',
    "AlTurshiah": 'الطرشية',
    "Mojour": 'قرية-مجعر',
    "Husayniyah": 'الحسينية',
    "AlMuhaffar": 'المحفّر',
    'Al-Iṯnayn': 'الاثنين',
    "UmmAlMelh": 'أم-الملح',
    "Thabhloten": 'ذعبلوتن',
    "AshShalfa": 'الشلفا',
    "AynQahtan": 'عين-قحطان',
    "Azfaa": 'عذفاء',
    "Abanat": 'أبانات',
    "AlJuaima'h": 'الجعيمة',
    "Alashwah": 'العشوة',
    "AlKarbus": 'الكربوس',
    "AlNeqrah": 'النقرة',
    "Baq'aAshargiyah": 'بقعاء-الشرقية',
    "Saqf": 'سقف',
    "Alshananah": 'الشنانة',
    "ArRishawiyah": 'الرشاوية',
    "AlAssim": 'قرى-ال-عاصم',
    "Edadh": 'عضاضة',
    "AlMasgi": 'المسقي',
    "Sunbah": 'صنبة',
    "Almajdirah": 'المجديرة',
    "AlKadami": 'الكدمي',
    'AL-MNSALA': 'المنزالة',
    "AlSewideiah": 'السويدية',
    "AlUzaym": 'العظيم',
    "AzZabirah": 'الزبيرة',
    "Alabadilah": 'العبادلة',
    "Aldhabyah": 'الظبية',
    "AlbakhtahNorth": 'البختة-الشمالية',
    "Aloqdah": 'العقدة',
    "Alsawarimah": 'الصوارمة',
    "Alwahlah": 'الوحلة',
    "AsSaban": 'السبعان',
    "AsShwimes": 'الشويمس',
    "AsSuwayriqiyah": 'السويرقية',
    "AbuShuayb": 'أبو-شعيب',
    "AthThamiriyah": 'الثامرية',
    "Badaa'iAlsadiah": 'بدائع-الصداعية',
    "Baishalolia": 'بيش-العليا',
    "Dayhamah": 'ديحمة',
    "Blhamr": 'بلحمر',
    "Ghezlanh": 'غزلانة',
    "FaydatAthqab": 'فيضة-اثقب',
    "Hadda": 'حدة',
    "Haly": 'حلي',
    "Hulayfa": 'الحليفة',
    "Juatha": 'جواثا',
    "JazanEconomicCity": 'المدينة-الاقتصادية',
    "Jamalah": 'الجمالة',
    "Khawarah": 'الخواره',
    "Madrak": 'المدرك',
    "Malha": 'الملحاء',
    "Naylat": 'نايلات',
    "QaimAldash": 'قائم-الدش',
    "Rohyyb": 'رحيب',
    "RasAlKhair": 'رأس-الخير',
    "RawdIbnHadi": 'روض-ابن-هادي',
    "SabtAlAlayah": 'سبت-العلايه',
    "RiaaAlbekr": 'ريع-البكر',
    "Tabah": 'طابة',
    "Su'aydan": 'سعيدان',
    "Takhayil": 'تخاييل',
    "UmSaad": 'ام-سعد',
    "AshShinanah": 'الشنانة',
    "AlJaradiyah": 'الجرادية',
    "Sultanah": 'سلطانة',
    "AlKharmaAlShimaliah": 'الخرماء-الشمالية',
    "AlMushaytiyah": 'المشيطية',
    "AlKihayfiyah": 'الكهيفية',
    "AlAwshaziyah": 'العوشزية',
    "AlMustajiddah": 'المستجدة',
    "AlAradiyah": 'العرادية',
    "AlJara": 'الجرعاء',
    "AsSafra": 'الصفراء',
    "AlFatkha": 'الفتخاء',
    "Mishar": 'مشار',
    "BaydaNatheel": 'بيضاء-النثيل',
    "AlNashifah": 'النشيفة',
    "AlJerisiyah": 'الجريسية',
    "AlJafdor": 'الجفدور',
    "AlLahien": 'اللحن',
    "AlHeno": 'الحنو',
    "AlBarakah": 'البركة',
    "AlHedilah": 'آل-حديلة',
    "AlHelami": 'آل-حلامي',
    "AlThibahA'AlAlJahal": 'الذيبة-آل-الجحل',
    "Helah": 'حلاة',
    "AlBkhtahCentral": 'البختة-الوسطي',
    "AlBkhtahNorth": 'البختة-الشمالية',
    "AlBkhtahSouth": 'البختة-الجنوبية',
    "AlHasamah": 'الحصامة',
    "AlJudaieen": 'الجديين',
    "AlKadhraAlShamalia": 'الخضراء-الشمالية',
    "AlKhadraAlJanoubia": 'الخضراء-الجنوبية',
    "AlMeghabbasheiah": 'المغبشية',
    "AlMuwassam": 'موسم',
    "AlRokobah": 'الركوبة',
    "Alajaibeiah": 'العجيبية',
    "Tharban": 'ثربان',
    "AhadTharban": 'أحد-ثربان',
    "Thajir": 'ثجر',
    "Algroup": 'الجروب',
    "Sha'abAlTheeb": 'شعب-الذيب',
    "Almuratafaah": 'المرتفعات',
    "NEOM": 'نيوم',
    "RasAlsheikhHamid": 'راس-الشيخ-حميد',
    "YanbuAlBahr": 'ينبع-البحر',
    "Almahalah": 'المحالة',
    "AlYazeed": 'آل-يزيد',
    "AlKhobh": 'الخوبة',
    "AlMoyhiyah": 'المويهية',
    "AlSahibah": 'الساحبة',
    "Tarafiyah": 'Tarafiyah',
    "tuaraf": 'tuaraf',
    "Ramah": 'الح',
    "AlHmanah": 'الحمنة',
    "Hegra": 'الحِجْر'
  }
  



const bankNameMapping = {
    80: "مصرف الراجحي",
    20: "بنك الرياض",
    "05": "مصرف الإنماء",
    10: "البنك الأهلي السعودي",
    55: "البنك السعودي الفرنسي",
    45: "البنك السعودي البريطاني",
    15: "بنك البلاد",
    65: "البنك السعودي للاستثمار",
    30: "البنك العربي",
    60: "بنك الجزيرة",
};
const bank_codes = {
    '80': 'RJHI',
    '20': 'RIBL',
    '05': 'INMA',
    '10': 'NCBK',
    '55': 'BSFR',
    '45': 'SABB',
    '15': 'ALBI',
    '65': 'SIBC',
    '30': 'ARNB',
    '60': 'BJAZ'
}


const customStyles = {
    container: (provided) => ({
        ...provided,
        direction: 'rtl', // Ensure text direction is RTL
    }),
    control: (provided) => ({
        ...provided,
        textAlign: 'right', // Align text to the right
        padding: '0', // Remove default padding
        border: '1px solid #ccc', // Border style
        borderRadius: '0.375rem', // Border radius for rounded corners
        boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.1)', // Optional: shadow for better visibility
    }),
    placeholder: (provided) => ({
        ...provided,
        textAlign: 'right', // Align placeholder text to the right
        direction: 'rtl', // Right-to-left text direction
    }),
    singleValue: (provided) => ({
        ...provided,
        textAlign: 'right', // Align selected value text to the right
        direction: 'rtl', // Right-to-left text direction
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: '0', // Remove default padding
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none', // Hide the indicator separator if needed
    }),
};

const apiUrl = process.env.REACT_APP_API_URL;



export const RefundForm = () => {
    const location = useLocation();
  const {
    totalAmount,
    totalAmountWithTax,
    notRefundable,
    refundFee,
    totalRefundedAmount
  } = location.state || {};
    const [citiesOptions, setCitiesOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showOtherFields, setShowOtherFields] = useState(false);
    const { t } = useTranslation();
    const { totalPrice, orderData, orderItems, setTotalPrice, setTotalQuantity, setOrderData, setOrderItems } = useAuth();
    const navigate = useNavigate();
    const paymentMethodIncludesTabbyOrTamara = orderData?.payment_method?.toLowerCase().includes('tabby') || orderData?.payment_method?.toLowerCase().includes('tamara');
    const getBankName = (iban) => {
        if (!iban.startsWith("SA") || iban.length !== 24) {
            return t("unknown-bank");
        }
        const bankIdentifier = iban.substring(4, 6);
        return t(bankNameMapping[bankIdentifier] || "unknown-bank");
    };

    const ibanValidationSchema = Yup.string()
        .transform(value => value.replace(/-/g, '')) // Removes dashes
        .matches(/^SA\d{22}$/, t('invalid-iban-format'))
        .required(t('iban-required'))
        .test("remove-spaces", t('iban-no-spaces'), (value) => !/\s/.test(value))
        .transform(value => value.replace(/\s+/g, '')); // Removes spaces

    const validationSchema = Yup.object({
        firstName: Yup.string().required(t("first-name-required")),
        secondName: Yup.string().required(t("second-name-required")),
        iban: !paymentMethodIncludesTabbyOrTamara ? ibanValidationSchema : Yup.string(),
        email: Yup.string().email(t("invalid-email-format")).required(t("email-required")),
        reason: Yup.string().required(t("reason-required")),
        city: Yup.string().required(t("city-required")),
        address: Yup.string().required(t("address-required")),
        others: showOtherFields ? Yup.string().required(t("reason-required")) : Yup.string(),
    });

    useEffect(() => {
        const cachedOrderData = JSON.parse(localStorage.getItem('orderData'));
        const cachedOrderItems = JSON.parse(localStorage.getItem('orderItems'));
        const cachedTotalPrice = localStorage.getItem('totalPrice');
        const cachedTotalQuantity = localStorage.getItem('totalQuantity');

        if (cachedOrderData && cachedOrderItems) {
            setOrderData(cachedOrderData);
            setOrderItems(cachedOrderItems);
            setTotalPrice(cachedTotalPrice);
            setTotalQuantity(cachedTotalQuantity);

            formik.setValues({
                firstName: cachedOrderData?.Customer?.first_name || "",
                secondName: cachedOrderData?.Customer?.last_name || "",
                iban: "",
                email: cachedOrderData?.Customer?.email || "",
                reason: "",
                city: getCityKey(cachedOrderData?.Customer?.city || ""),
                address: cachedOrderData?.Customer?.address || ""
            });

            setCitiesOptions(Object.keys(cities).map(key => ({
                value: key,
                label: cities[key]
            })));
        }
    }, []);


    const handleReasonChange = (event) => {
        const selectedReason = event.target.value;
        formik.setFieldValue('reason', selectedReason);
        setShowOtherFields(selectedReason === 'other'); // Show textarea if 'other' is selected
    };

    const handleLoading = (value) => setLoading(value)

    const getCityKey = (arabicCityName) => {
        return Object.keys(cities).find(key => cities[key] === arabicCityName);
    };

    const getBankKey = (bankValue) => {
        const key = Object.keys(bankNameMapping).find(key => bankNameMapping[key] === bankValue)
        return bank_codes[`${key}`]
    }

    const formik = useFormik({
        initialValues: {
            firstName: orderData?.Customer?.first_name || "",
            secondName: orderData?.Customer?.last_name || "",
            iban: "",
            email: orderData?.Customer?.email || "",
            reason: "",
            city: citiesOptions.find(option => option.value === orderData?.Customer?.city) || "",
            address: orderData?.Customer?.address || "",
            others: "", // Field for the "other" reason
        },
        validationSchema,
        onSubmit: async (values) => {
            const bankName = getBankName(values.iban);

            if (!paymentMethodIncludesTabbyOrTamara && bankName === t("unknown-bank")) {
                toast.error(t("invalid-bank"));
                return;
            }
            const returnItems = orderItems
                .filter(item => item.refund_quantity > 0)
                .map(item => ({
                    product_id: item.Product.id,
                    quantity: item.refund_quantity
                }));
            const payload = {
                payment_method: orderData?.payment_method,
                items: returnItems,
                refund_amount: totalPrice - 19 > 0 ? parseFloat((totalPrice - 19).toFixed(2)) : 0,
                city: cities[values.city],
                reason: values.reason === "other" ? values.others : values.reason,
                first_name: values.firstName,
                last_name: values.secondName,
                email: values.email,
                bank_code: '',
                address: values.address,
                total_with_tax: totalAmountWithTax,
                total_refundable_amount: totalAmount,
                total_non_refundable_amount: notRefundable,
                return_fees: refundFee
            }

            if (values.iban) {
                payload.iban = values.iban
                payload.bank_code = getBankKey(getBankName(formik.values.iban))
            }
            const token = localStorage.getItem('token');
            try {
                handleLoading(true)
                const response = await axios.post(
                    `${apiUrl}/refund-requests`,
                    payload,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }
                    }
                );
                handleLoading(false)
                if (response?.data?.uuid) {
                    toast.success("successfully submitted the refund request")
                    setTotalPrice(0);
                    setTotalQuantity(0);
                    localStorage.removeItem('orderData');
                    localStorage.removeItem('orderItems');
                    localStorage.removeItem('totalPrice');
                    localStorage.removeItem('totalQuantity');
                    setTimeout(() => {
                        navigate(`/bank-info/confirmation/${response?.data?.uuid}`);
                    }, 1000)
                }
            } catch ({ response }) {
                handleLoading(false)
                toast.error(response?.data?.message)
            }
        },
    });

    return (
        <div className="flex justify-center items-start mt-12 p-4">
            <ToastContainer />
            <div className="w-full md:w-1/2 lg:w-1/3 bg-white p-6 rounded-lg shadow-lg">
                <form onSubmit={formik.handleSubmit} className="space-y-5">
                    <div className="relative">
                        <label className="text-sm text-gray-600 mr-2 flex justify-end">
                            {t("first-name")}
                        </label>
                        <input
                            type="text"
                            name="firstName"
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="form-input w-full text-right placeholder-right border border-gray-300 p-2"
                            placeholder={t("first-name")}
                        />
                        {formik.touched.firstName && formik.errors.firstName ? (
                            <div className="text-red-500 text-sm mt-1 text-right">
                                {formik.errors.firstName}
                            </div>
                        ) : null}
                    </div>

                    <div className="relative">
                        <label className="text-sm text-gray-600 mr-2 flex justify-end">
                            {t("second-name")}
                        </label>
                        <input
                            type="text"
                            name="secondName"
                            value={formik.values.secondName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="form-input w-full text-right placeholder-right border border-gray-300 p-2"
                            placeholder={t("second-name")}
                        />
                        {formik.touched.secondName && formik.errors.secondName ? (
                            <div className="text-red-500 text-sm mt-1 text-right">
                                {formik.errors.secondName}
                            </div>
                        ) : null}
                    </div>

                    {!paymentMethodIncludesTabbyOrTamara && (
                        <div className="relative">
                            <label className="text-sm text-gray-600 mr-2 flex justify-end">
                                {t("iban")}
                            </label>
                            <input
                                type="text"
                                name="iban"
                                value={formik.values.iban}
                                onChange={(e) => {
                                    // Remove both hyphens and spaces
                                    const cleanedValue = e.target.value.replace(/[-\s]/g, '');
                                    formik.setFieldValue('iban', cleanedValue);
                                }}
                                onBlur={formik.handleBlur}
                                className="form-input w-full text-right placeholder-right border border-gray-300 p-2"
                                placeholder={t("iban")}
                            />
                            {formik.touched.iban && formik.errors.iban ? (
                                <div className="text-red-500 text-sm mt-1 text-right">
                                    {formik.errors.iban}
                                </div>
                            ) : null}
                            {formik.values.iban && !formik.errors.iban && (
                                <div className="text-gray-700 text-sm mt-2">
                                    {t("bank-name")}: {getBankName(formik.values.iban)}
                                </div>
                            )}
                        </div>
                    )}

                    <div className="relative">
                        <label className="text-sm text-gray-600 mr-2 flex justify-end">
                            {t("email")}
                        </label>
                        <input
                            type="email"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="form-input w-full text-right placeholder-right border border-gray-300 p-2"
                            placeholder={t("email")}
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <div className="text-red-500 text-sm mt-1 text-right">
                                {formik.errors.email}
                            </div>
                        ) : null}
                    </div>

                    <div className="flex flex-col items-end space-y-4">
                        <div className="relative w-full">
                            <label className="text-sm text-gray-600 mr-2 flex justify-end">
                                {t("select-reason")}
                            </label>
                            <select
                                name="reason"
                                value={formik.values.reason}
                                onChange={handleReasonChange}
                                onBlur={formik.handleBlur}
                                className="form-select w-full text-right appearance-none pr-2 py-2 border border-gray-400"
                            >
                                <option value="" disabled>
                                    {t("select-reason")}
                                </option>
                                <option value="perfume didn't suite me">
                                    {t("perfume-didn't-suite-me")}
                                </option>
                                <option value="I’d like to try another perfume">
                                    {t("I’d like to try another perfume")}
                                </option>
                                <option value="other">
                                    {t("other")}
                                </option>
                            </select>
                            <div className="absolute top-[65%] left-0 transform -translate-y-[40%] flex items-center px-2 pointer-events-none">
                                <FaChevronDown className="text-gray-400" />
                            </div>
                            {formik.touched.reason && formik.errors.reason ? (
                                <div className="text-red-500 text-sm mt-1 text-right">
                                    {formik.errors.reason}
                                </div>
                            ) : null}
                        </div>


                        {showOtherFields && (
                            <div className="relative w-full">
                                <label className="text-sm text-gray-600 mr-2 flex justify-end">
                                    {t("other")}
                                </label>
                                <input
                                    type="text"
                                    name="others"
                                    value={formik.values.others}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className="form-input w-full text-right placeholder-right border border-gray-400 p-2"
                                    placeholder={t("other")}
                                />
                                {formik.touched.others && formik.errors.others ? (
                                    <div className="text-red-500 text-sm mt-1 text-right">
                                        {formik.errors.others}
                                    </div>
                                ) : null}
                            </div>
                        )}

                        <div className="relative w-full">
                            <label className="text-sm text-gray-600 mr-2 flex justify-end">
                                {t("select-city")}
                            </label>
                            <Select
                                name="city"
                                options={citiesOptions}
                                value={citiesOptions.find(option => option.value === formik.values.city)}
                                onChange={(selectedOption) => {
                                    formik.setFieldValue('city', selectedOption.value);
                                }}
                                onBlur={formik.handleBlur}
                                placeholder={t("select-city")}
                                isClearable={false}
                                styles={customStyles}
                            />
                            {formik.touched.city && formik.errors.city ? (
                                <div className="text-red-500 text-sm mt-1 text-right">
                                    {formik.errors.city}
                                </div>
                            ) : null}
                        </div>

                        <div className="relative w-full">
                            <label className="text-sm text-gray-600 mr-2 flex justify-end">
                                {t("address")}
                            </label>
                            <textarea
                                name="address"
                                value={formik.values.address}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className="form-textarea w-full text-right placeholder-right border border-gray-400 p-2 h-20 resize-none"
                                placeholder={t("address")}
                            />
                            {formik.touched.address && formik.errors.address ? (
                                <div className="text-red-500 text-sm mt-1 text-right">
                                    {formik.errors.address}
                                </div>
                            ) : null}
                        </div>
                    </div>

                    <div className="text-left mt-6">
                        <button
                            disabled={loading}
                            type="submit"
                            className={`bg-black text-white py-2 px-4 rounded ${loading ? 'cursor-not-allowed opacity-50' : ''}`}
                        >
                            {t("complete-refund-request")}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

